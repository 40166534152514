<template>
  <main class="mb-16 px-4 max-w-sm md:max-w-3xl lg:max-w-6xl mx-auto">
     <h2 class="my-10 text-2xl font-semibold text-gray-700 dark:text-gray-200 flex">
      <router-link class="w-10 block" tag="a" :to="{ name: 'orders'}" >
        <svg
          class="h-8 w-8 fill-current text-gray-700 dark:text-gray-200"
          style="enable-background:new 0 0 512 512;"
          version="1.1"
          viewBox="0 0 512 512"
          xml:space="preserve"
        >
          <polygon
            points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "
          />
        </svg>
      </router-link>
  {{ title_page }}   
   </h2>
 <div class="bg-white rounded-lg shadow-md dark:bg-gray-800 pb-12">
      <form @submit.prevent="submit" enctype="multipart/form-data">
        <section>
          <hr
            class="rounded-t-lg mb-4 lg:mb-10 border-g dark:border-gray-600"
          />
          <div class="pt-8">
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label
                  for="customerName"
                  class="block text-sm font-medium text-gray-700"
                >
                 CustomerName
                </label>
                <div class="mt-1">
                  <input
                    disabled
                    type="text"
                    v-model="customerName"
                    name="customerName"
                    id="customerName"
                    autocomplete="given-name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>     <div class="sm:col-span-3">
                <label
                  for="customerName"
                  class="block text-sm font-medium text-gray-700"
                >
                 Email
                </label>
                <div class="mt-1">
                  <input
                    disabled
                    type="text"
                    v-model="email"
                    name="email"
                    id="email"
                    autocomplete="given-name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>     
              <div class="sm:col-span-3">
                <label
                  for="phoneNumber"
                  class="block text-sm font-medium text-gray-700"
                >
                 PhoneNumber
                </label>
                <div class="mt-1">
                  <input
                    disabled
                    type="text"
                    v-model="phoneNumber"
                    name="phoneNumber"
                    id="phoneNumber"
                    autocomplete="given-name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>   
                <div class="sm:col-span-3">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                >
                 Country
                </label>
                <div class="mt-1">
                  <input
                    disabled
                    type="text"
                    v-model="country"
                    name="country"
                    id="country"
                    autocomplete="given-name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>    
               <div class="sm:col-span-3">
                <label
                  for="city"
                  class="block text-sm font-medium text-gray-700"
                >
                 City
                </label>
                <div class="mt-1">
                  <input
                    disabled
                    type="text"
                    v-model="city"
                    name="city"
                    id="city"
                    autocomplete="given-name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
                     <div class="sm:col-span-3">
                <label
                  for="city"
                  class="block text-sm font-medium text-gray-700"
                >
                 Address
                </label>
                <div class="mt-1">
                  <input
                    disabled
                    type="text"
                    v-model="address"
                    name="Address"
                    id="Address"
                    autocomplete="given-name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>    
                 <div class="sm:col-span-3">
                <label
                  for="paymentMethod"
                  class="block text-sm font-medium text-gray-700"
                >
                 PaymentMethod
                </label>
                <div class="mt-1">
                <label
                  for="paymentMethod"
                    class="shadow-sm border border-solid p-2   focus:ring-indigo-500   block w-full sm:text-sm border-gray-300 rounded-md"
                >
                 {{paymentMethod |paymentMethod }}
                </label>              
                </div>
              </div>  
                  <div class="sm:col-span-3">
                <label
                  for="grandTotal"
                  class="block text-sm font-medium text-gray-700"
                >
                 GrandTotal
                </label>
                <div class="mt-1">
                  <input
                    disabled
                    type="text"
                    v-model="grandTotal"
                    name="grandTotal"
                    id="grandTotal"
                    autocomplete="given-name"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>      
         
            </div>
          </div>       
        </section>
      </form>
    </div>
    <div v-if="orderProducts.length != 0" class="w-full whitespace-no-wrap">
        <div
          class="flex justify-between items-center pt-4 pb-2 text-xs font-semibold tracking-wide text-left text-gray-500 border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
        >
          <span class="w-1/2 md:w-1/4 px-4 py-3">productName</span>
          <span class="w-1/2 md:w-1/4 px-4 py-3">quantity</span>
          <span class="w-1/2 md:w-1/4 px-4 py-3">price</span>
          <span class="w-1/2 md:w-1/4 px-4 py-3">Size</span>
          <span class="w-1/2 md:w-1/4 px-4 py-3">Color</span>
          <span class="w-1/2 md:w-1/4 px-4 py-3">Procedures</span>
                                      
        </div>
        <div class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
          <div
            :key="id"     
            v-for="(orderProduct, id) in orderProducts"
            class="flex justify-between items-center  text-gray-700 dark:text-gray-400 "
          >
            <span class="w-1/2 md:w-1/5 px-4 py-3 text-sm">
              {{ orderProduct.productName }}
            </span>

            <span class="w-1/2 md:w-1/5 px-4 py-3 text-sm">
                <p class="font-semibold">{{ orderProduct.qty }}</p>
            </span>
              <span class="w-1/2 md:w-1/5 px-4 py-3 text-sm">
                <p class="font-semibold">{{ orderProduct.productPrice }}</p>
            </span>
            <span class="w-1/2 md:w-1/5 px-4 py-3 text-sm">
                <p class="font-semibold">{{ orderProduct.size }}</p>
            </span>
            <span class="w-1/2 md:w-1/5 px-4 py-3 text-sm">
              {{ orderProduct.color }}
            </span>
            
            <span class="w-1/2 md:w-1/5 px-4 py-3 text-sm  ">
              <div class=" flex justify-between items-center">               
                <div class="">
                  <button @click="deleteButton(orderProduct.orderProductId, id)">
                    <svg
                      class="w-6 h-6"
                      height="512pt"
                      viewBox="-57 0 512 512"
                      width="512pt"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m156.371094 30.90625h85.570312v14.398438h30.902344v-16.414063c.003906-15.929687-12.949219-28.890625-28.871094-28.890625h-89.632812c-15.921875 0-28.875 12.960938-28.875 28.890625v16.414063h30.90625zm0 0"
                      />
                      <path
                        d="m344.210938 167.75h-290.109376c-7.949218 0-14.207031 6.78125-13.566406 14.707031l24.253906 299.90625c1.351563 16.742188 15.316407 29.636719 32.09375 29.636719h204.542969c16.777344 0 30.742188-12.894531 32.09375-29.640625l24.253907-299.902344c.644531-7.925781-5.613282-14.707031-13.5625-14.707031zm-219.863282 312.261719c-.324218.019531-.648437.03125-.96875.03125-8.101562 0-14.902344-6.308594-15.40625-14.503907l-15.199218-246.207031c-.523438-8.519531 5.957031-15.851562 14.472656-16.375 8.488281-.515625 15.851562 5.949219 16.375 14.472657l15.195312 246.207031c.527344 8.519531-5.953125 15.847656-14.46875 16.375zm90.433594-15.421875c0 8.53125-6.917969 15.449218-15.453125 15.449218s-15.453125-6.917968-15.453125-15.449218v-246.210938c0-8.535156 6.917969-15.453125 15.453125-15.453125 8.53125 0 15.453125 6.917969 15.453125 15.453125zm90.757812-245.300782-14.511718 246.207032c-.480469 8.210937-7.292969 14.542968-15.410156 14.542968-.304688 0-.613282-.007812-.921876-.023437-8.519531-.503906-15.019531-7.816406-14.515624-16.335937l14.507812-246.210938c.5-8.519531 7.789062-15.019531 16.332031-14.515625 8.519531.5 15.019531 7.816406 14.519531 16.335937zm0 0"
                      />
                      <path
                        d="m397.648438 120.0625-10.148438-30.421875c-2.675781-8.019531-10.183594-13.429687-18.640625-13.429687h-339.410156c-8.453125 0-15.964844 5.410156-18.636719 13.429687l-10.148438 30.421875c-1.957031 5.867188.589844 11.851562 5.34375 14.835938 1.9375 1.214843 4.230469 1.945312 6.75 1.945312h372.796876c2.519531 0 4.816406-.730469 6.75-1.949219 4.753906-2.984375 7.300781-8.96875 5.34375-14.832031zm0 0"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </span>
          </div>
        </div>
          <div
          class="flex justify-between items-center px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
        >
             <el-pagination
               background
             :page-size="pageSize" layout="prev, pager, next" 
             :total="total"
             :current-page.sync="pageNo" @current-change="PageChanged"
             class="pagination justify-content-center pagination-sm ">
             </el-pagination>
       
        </div>
      </div>
      <div
        v-else
        id="emptyState"
        class="text-gray_5 text-sm text-center h-96 flex items-center justify-center"
      >
        <div
          v-if="loading"
          id="testtest"
          class="h-64 flex justify-center items-center"
        >
          <svg
            class="w-10 h-w-10 rounded-full"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#fff"
          >
            <g fill="none">
              <g transform="translate(1 1)" stroke-width="2">
                <circle
                  stroke="#E1E7EC"
                  stroke-opacity=".5"
                  cx="18"
                  cy="18"
                  r="18"
                />
                <path stroke="#AD4BB8" d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
        <div
          v-else
          class="h-64 flex justify-center items-center w-full bg-white  dark:bg-gray-800"
        >
          No orderProducts yet..
        </div>
      </div>
  </main>
</template>

<script>
//   import { required, numeric } from "vuelidate/lib/validators";

export default {
  validations: {
    //   name_arabic: { required },
  },
  created() {
    window.scrollTo(top);
    this.GetColor();
    this.GetSize();

  },
  mounted() {
    // Get by id
    var orderId = this.$route.params.order;
    if (this.$route.params.order) {
      this.$http.ordersService
        .GetOrderForView(orderId)
        .then((res) => {
          this.loading = false;
          this.Successed = true;
          this.addSuccessed = res.data.response;
          this.data = res.data;
          this.customerName = res.data.result.orders.customerName;
          this.email = res.data.result.orders.email;
          this.phoneNumber = res.data.result.orders.phoneNumber;
          this.country = res.data.result.orders.country;
          this.city = res.data.result.orders.city;
          this.address = res.data.result.orders.address;
          this.paymentMethod = res.data.result.orders.paymentMethod;
          this.grandTotal = res.data.result.orders.total;
          this.orderProducts = res.data.result.orders.orderProducts;
          this.total = res.data.result.total;
          })
        .catch((err) => {
          this.loading = false;
          this.Successed = false;
          this.addErorr = err;
        });
      this.title_page = "Order Details";
     }
  },
  data() {
    return {
      showAlert: false,
      loading: false,
      showDelete: false,
      Successed: false,
      addErorr: null,
      pageNo:1,
      pageSize:5,
      data: [],
      title_page: "",
      submit_text: "",
      file: "",
      customerName:null,
      email:null,
      phoneNumber:null,
      country:null,
      city:null,
      address:null,
      paymentMethod:null,
      grandTotal:null,
      orderProducts:[],
      total:null,
    };
  },
  methods: {
      PageChanged(pageNo) {
      this.pageNo = pageNo;
      this.getProducts();
    },
    reverse_the_value_of_language(x, y) {
      if (x == "language_Arabic") {
        if (y == 1) {
          this.language_Arabic = 0;
        } else {
          this.language_Arabic = 1;
        }
      }
      if (x == "language_English") {
        if (y == 1) {
          this.language_English = 0;
        } else {
          this.language_English = 1;
        }
      }

      if (x == "language_Others") {
        if (y == 1) {
          this.language_Others = 0;
        } else {
          this.language_Others = 1;
        }
      }
    },
    selectFile(input) {
      let reader = new FileReader();
      reader.onload = (event) => {
        this.productMainImage = event.target.result;
      };
      reader.readAsDataURL(input.target.files[0]);
    },
    submit() {
      this.showAlert = true;
      this.loading = true;
      var newAttributes = {
     productId: Number(this.$route.params.product),
     sizeId : this.sizeId,
     colorId :  this.colorId,
     qty :  Number(this.quantity),
     price :   Number(this.price),
      };
    //   if (this.$route.params.product) {
    // var updateAttributes = {
    //productId:this.$route.params.product,
    // orderProductId:this.orderProductId,
    //  sizeId :  this.sizeId,
    //  colorId :  this.colorId,  
    //  qty :  this.quantity,
    //  price :  this.price,
    //  productId: this.$route.params.product,
    //     };
    //     this.$http.productsService
    //       .Editorders(updateAttributes)
    //       .then((res) => {
    //         this.loading = false;
    //         this.Successed = true;
    //         this.addSuccessed = res.data.response;
    //       })
    //       .catch((err) => {
    //         this.loading = false;
    //         this.Successed = false;
    //         this.addErorr = err;
    //       });
    //   } else
        this.$http.productsService
          .Addorders(newAttributes)
          .then((res) => {
            this.loading = false;
            this.Successed = true;
            this.addSuccessed = res.data.response;
          this.orders.unshift({
          orderProductId:  res.data.result.addedAttributes.orderProductId,
          size : res.data.result.addedAttributes.size,
          qty : res.data.result.addedAttributes.qty,
          price : res.data.result.addedAttributes.price,
          color : res.data.result.addedAttributes.color,
          status :  res.data.result.addedAttributes.status,     
          });
          })
          .catch((err) => {
            this.loading = false;
            this.Successed = false;
            this.addErorr = err.data.result.message;
          });
    } ,
    deleteButton(orderProductId, index) {
      this.loading = true;
      this.$http.productsService
        .Deleteorders(orderProductId)
        .then((res) => {
          this.addSuccessed = res.data.response;
          this.orders.splice(index, 1);
           this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.Successed = false;
          this.addErorr = err.data.result.message;
        });
    },
    GetSize(){
        this.$http.lookupService
          .GetSizes()
          .then((res) => {
          this.data = res.data;
          this.sizes = res.data.result.sizes;
          })
          .catch((err) => {          
            this.addErorr = err;
          });
    },
    GetColor(){
      this.$http.lookupService
          .GetColors()
          .then((res) => {
          this.data = res.data;
          this.colors = res.data.result.colors;
        
          })
          .catch((err) => {        
            this.addErorr = err;
          });
    }
  },
};
</script>

<style>
.test {
  background-color: #035388;
  color: white;
}
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

.submit button[disabled],
.submit button[disabled]:hover,
.submit button[disabled]:active {
  border: 1px solid #ccc;
  background-color: transparent;
  color: #ccc;
  cursor: not-allowed;
}
</style>
